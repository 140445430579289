import React, { useState, useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { css } from "styled-components"
import tw from "twin.macro"
import Heading from "general/components/Heading"
import LoginForm from "form/components/LoginForm"
import CustomTokenForm from "form/components/CustomTokenForm"
import { adminLogin, loginWithCustomToken } from "auth/redux"
import { navigate } from "gatsby"

function LoginPage(props) {
  const { adminLogin, loginWithCustomToken } = props
  const [idToken, setIdToken] = useState("")
  const message = useSelector(state => state.auth.status)

  useEffect(() => {
    if (!process.env.GATSBY_DEMO_MODE) {
      return navigate("/login")
    }

    const searchParams = new URLSearchParams(window.location.search)
    const uid = searchParams.get("uid")
    const idToken = searchParams.get("token")

    if (idToken) {
      setIdToken(idToken)
    }

    if (uid && idToken) {
      loginWithCustomToken({ uid, idToken })
    }
  }, [])

  return (
    <div css={tw`flex flex-col justify-center items-center min-h-screen`}>
      {!idToken ? (
        <div
          css={[
            tw`w-full max-w-xs bg-white px-8 py-8 shadow-md`,
            css`
              min-width: 320px;
              max-width: 420px;
            `,
          ]}
        >
          <div className="mb-8">
            <Heading>JCURVE Admin Login</Heading>
          </div>
          <LoginForm onAction={adminLogin} />
        </div>
      ) : (
        message
      )}
    </div>
  )
}

export default connect(undefined, { adminLogin, loginWithCustomToken })(
  LoginPage
)
